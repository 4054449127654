import React from 'react';

// react-bootstrap components
import { Card } from 'react-bootstrap';
const users = {
	austinhankwitz: {
		logo: 'https://static.moneymade.io/marketplaceAssets/austinhankwitz/image%20(13).png',
		name: 'Austin Hankwitz',
		description: 'Finance & Investing CNBC | Bloomberg | NY Times | NOT ADVICE',
		headerImage:
			'https://static.moneymade.io/marketplaceAssets/austinhankwitz/image%20(12).png',
	},
	yourrichbff: {
		logo: 'https://static.moneymade.io/marketplaceAssets/yourrichbff/influencer-dae0b9ad_1665242460246_original._CR69%2C0%2C898%2C898_._FMjpg_.jpeg',
		name: 'Your Rich BFF',
		description:
			'University of Chicago grad, ex-Wall Street Trader, Strategy Sales Partner at a Tech company',
		headerImage:
			'https://static.moneymade.io/marketplaceAssets/yourrichbff/influencer-dae0b9ad_1665242460237_original._CR0%2C284%2C2308%2C512_._FMjpg_.jpeg',
	},
	applecrider: {
		logo: 'https://static.moneymade.io/marketplaceAssets/applecrider/channels4_profile.jpg',
		name: 'Apple Crider',
		description: 'Build Your Business The Right Way',
		headerImage:
			'https://static.moneymade.io/marketplaceAssets/applecrider/2022-10-17_19-45.jpeg',
	},
};
function User() {
	const userSlug = window.location.pathname.replace('/', '');
	return (
		<>
			<Card className="card-user">
				<div className="card-image">
					<img
						alt="..."
						src={
							users[userSlug]
								? users[userSlug].headerImage
								: require('./assets/img/monies.png')
						}
					></img>
				</div>
				<Card.Body>
					<div className="author">
						<img
							alt="..."
							className="avatar border-gray"
							src={
								users[userSlug]
									? users[userSlug].logo
									: require('./assets/img/faces/face-3.jpg')
							}
						></img>
						<h2 className="title">
							{users[userSlug] ? users[userSlug].name : `Rich'n Round`}
						</h2>

						<p className="description">
							{users[userSlug]
								? users[userSlug].description
								: `Ex-Wall St. trader now financial creator`}
						</p>
					</div>
				</Card.Body>
				<div className="button-container mr-auto ml-auto">
					<div
						id="marketplace-3a34ff51-9b88-493e-9b7c-235a09f9c129"
						className="money-made-embed"
						data-name="Marketplace"
						data-width="100%"
						data-height="0"
						data-embed-widget="marketplace"
						data-utm-medium="embed"
						data-utm-source={users[userSlug] ? userSlug : 'marketplace_demo'}
						data-utm-campaign="REPLACE_WITH_PAGE_SLUG"
						style={{ display: 'block' }}
					></div>
				</div>
			</Card>
		</>
	);
}

export default User;
